<template>
  <div class="full-width full-height">
    <v-card
      class="full-height full-width d-flex flex-column complex-search-container"
      style="position: relative"
      tile
    >
      <div class="full-width pa-2">
        <form
          @submit.stop="changeParams()"
          style="position: relative"
          id="complext-search-input-container"
        >
          <SearchInput
            @click:select="onSelectSearch"
            :search.sync="params.search"
            @click:clear="onResetSearch()"
            :loading="c_loading"
            @click:icon-search="changeParams()"
            @input:enter="changeParams()"
            @click:focus="onChangeFocus"
            :outlined="!isFocus"
            :class="{ 'elevation-2': isFocus }"
            :solo="isFocus"
            attach="#complext-search-input-container"
          />
        </form>
      </div>

      <v-card flat tile class="full-height d-flex flex-column overflow-hidden">
        <div class="flex-grow-0">
          <div class="py-2" style="position: relative">
            <v-expansion-panels flat v-model="panels">
              <v-expansion-panel>
                <v-expansion-panel-header
                  class="pa-4 pb-2"
                  style="min-height: 40px"
                >
                  <div class="text-subtitle-2">Tìm theo khu vực</div>
                </v-expansion-panel-header>
                <v-divider class="mx-3 mb-6" v-if="panels === 0"></v-divider>
                <v-expansion-panel-content>
                  <SearchFilter
                    :params.sync="params"
                    @click:search="onChangeFilter()"
                  >
                  </SearchFilter>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <div v-if="itemsSearch.length > 0" class="px-3">
            <v-divider></v-divider>
          </div>
        </div>
        <div
          class="flex-grow-0"
          v-if="!c_loading && place && pagination.total > 0"
        >
          <div class="px-4 text-body-2 py-2">
            {{ place.name }} ở {{ regionName }} có
            {{ resultName }}
          </div>
          <div class="flex-grow-0 d-flex px-4 align-center">
            <div class="text-caption">
              {{ (pagination.page - 1) * pagination.perpage + 1 }} -
              {{
                pagination.page * pagination.perpage > pagination.total
                  ? pagination.total
                  : pagination.page * pagination.perpage
              }}
            </div>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              :disabled="pagination.page < 2"
              @click="onPrevPage()"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              icon
              small
              @click="onNextPage()"
              :disabled="
                pagination.total <= pagination.page * pagination.perpage
              "
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </div>
          <div v-if="itemsSearch.length > 0" class="px-3">
            <v-divider></v-divider>
          </div>
        </div>
        <div
          class="
            flex-grow-1
            d-flex
            full-height
            flex-column
            position-relative
            overflow-hidden
          "
        >
          <template v-if="!loading">
            <div
              v-if="!isEmptySearch || showItemsSearch.length"
              class="flex-grow-1 overflow-auto"
            >
              <div v-if="showItemsSearch.length > 0" class="pb-0">
                <template v-for="(item, i) in showItemsSearch">
                  <SearchPlaceCard
                    class="px-2 pt-2"
                    flat
                    :item="item"
                    @click="onSelectSearch(item)"
                    @mouseover="onHoverItem(item)"
                    @mouseleave="onHoverItem()"
                    :key="i + 'item'"
                  />
                  <v-divider
                    :key="i + 'divider'"
                    v-if="i != showItemsSearch.length - 1"
                  ></v-divider>
                </template>
              </div>
              <div v-else class="pa-4">
                <div>
                  <p class="text-subtitle-2 font-weight-regular mb-1">
                    Không có {{ layerName }} trong khu vực bạn chọn
                  </p>
                  <p class="text-caption">
                    Để xem kết quả khác, hãy thử điều chỉnh các bộ lọc của bạn
                    hoặc <a @click="onResetParams()">xóa tất cả bộ lọc</a>.
                  </p>
                  <p class="text-caption" v-if="params.isCheckMove">
                    Hãy thử di chuyển hoặc thu phóng bản đồ.
                  </p>
                </div>
              </div>
            </div>
            <v-spacer v-else></v-spacer>
            <div v-if="showItemsSearch.length > 0" class="px-3">
              <v-divider></v-divider>
            </div>
            <div class="flex-grow-0 pt-1 pb-2 px-4 text-caption">
              <div class="d-flex align-center">
                <input
                  type="checkbox"
                  id="check-move-map"
                  class="d-inline-block"
                  v-model="params.isCheckMove"
                />
                <label for="check-move-map" class="d-inline-block pl-1">
                  Cập nhật kết quả khi bản đồ di chuyển
                </label>
              </div>
            </div>
          </template>
          <v-overlay absolute :value="loading"> </v-overlay>
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { debounce } from "@/utils/Debounce";
import SearchInput from "./SearchInput.vue";
import { API_SEARCH } from "@/service/api";
import SearchPlaceCard from "../Result/SearchPlaceCard.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    SearchInput,
    SearchPlaceCard,
    SearchFilter: () => import("./SearchFilter.vue"),
  },
  props: {
    search: String,
    loading: Boolean,
    items: { type: Array, default: () => [] },
  },
  data: () => ({
    pagination: { page: 1, perpage: 20, total: 0 },
    isFocus: false,
    panels: 0,
    showItemsSearch: [],
  }),
  computed: {
    ...mapGetters({
      layers: "map/layers",
      place: "map/place",
      countFeatureLayer: "search/countFeatureLayer",
    }),
    resultName() {
      return Object.keys(this.layers)
        .filter((key) => !!this.countFeatureLayer[key])
        .map((key) => `${this.countFeatureLayer[key]} ${this.layers[key].name}`)
        .join(", ");
    },
    params: {
      get() {
        return this.$store.getters["search/params"];
      },
      set(params) {
        this.$store.commit("search/setParams", params);
      },
    },
    regionName() {
      if (!this.params.state && !this.params.district) {
        return "Toàn quốc";
      }
      if (!this.params.district) {
        return this.params.state.nameWithType;
      }
      if (this.params.district) {
        return `${this.params.district.nameWithType}, ${this.params.state.nameWithType}`;
      }
      return "";
    },
    layerName() {
      return Object.keys(this.layers)
        .map((key) => this.layers[key].name)
        .join(", ");
    },
    itemsSearch: {
      get() {
        return this.items;
      },
      set(value) {
        this.$emit("update:items", value);
      },
    },
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    c_loading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$emit("update:loading", value);
      },
    },
    isEmptySearch() {
      return Object.keys(this.params).every((key) => !this.params[key]);
    },
  },
  watch: {
    search: {
      handler(value) {
        if (value) this.params.search = value;
      },
      immediate: true,
    },
    "params.isCheckMove": {
      handler(value) {
        this.fetchData();
      },
    },
    "params.bbox": {
      handler(value) {
        if (value && this.params.isCheckMove) {
          this.pagination.page = 1;
          this.fetchData();
        }
      },
    },
  },
  methods: {
    onSearch() {
      this.params.isCheckMove = false;
      this.pagination.page = 1;
      return this.fetchData();
    },
    onChangeFocus(e) {
      this.isFocus = e;
    },
    onNextPage() {
      if (
        this.pagination.total <=
        this.pagination.page * this.pagination.perpage
      ) {
        return;
      }
      this.pagination.page++;
      this.onUpdateItemSearchInLayer();
    },
    onPrevPage() {
      if (this.pagination.page < 2) {
        return;
      }
      this.pagination.page--;
      this.onUpdateItemSearchInLayer();
    },
    changeParams() {
      this.pagination.page = 1;
      this.fetchData();
    },
    fetchData() {
      this.c_loading = true;
      let params = { ...this.params };
      let bbox = null;
      // if (this.params.commune) {
      //   bbox = this.params.commune.bbox;
      // } else if (this.params.district) {
      //   bbox = this.params.district.bbox;
      // } else if (this.params.state) {
      //   bbox = this.params.state.bbox;
      // }

      return API_SEARCH(params)
        .then((res) => {
          const count = {};
          let itemsSearch = res.list.map((item) => {
            let layer_id = item.placeTypeId;
            if (!count[layer_id]) {
              count[layer_id] = 0;
            }
            count[layer_id]++;
            return {
              ...item,
              place: this.layers[layer_id],
            };
          });
          this.itemsSearch = itemsSearch;
          this.pagination = res.pagination;
          this.$store.commit("search/setCountFeatureLayer", count);
          this.$nextTick(() => {
            this.onUpdateItemSearchInLayer(bbox);
          });
        })
        .finally(() => {
          this.c_loading = false;
        });
    },
    onUpdateItemSearchInLayer(bbox) {
      let start = (this.pagination.page - 1) * this.pagination.perpage;
      let end = this.pagination.page * this.pagination.perpage;
      let items = this.itemsSearch.slice(start, end);

      items = this.$store.getters["map/itemSearch"](
        items.map((x) => `${x.placeTypeId}-${x.id}`)
      ).filter((x) => !!x);
      this.showItemsSearch = items;
      this.$root.$emit("show:complex-search", {
        items,
        bbox,
        options: { fillBound: !this.params.isCheckMove },
        count: this.countFeatureLayer,
      });
    },
    onChangeSearch: debounce(function (value) {
      if (!value) {
        this.onResetSearch();
        return;
      }
      this.params.search = value;
      this.pagination.page = 1;
      this.fetchData();
    }, 200),
    onSelectSearch(e) {
      this.$emit("click:select", e);
    },
    onResetSearch() {
      this.itemsSearch = [];
      this.$emit("click:reset");
    },
    onResetParams() {
      this.params = {
        state: null,
        district: null,
        commune: null,
        isCheckMove: false,
        search: "",
      };
      this.fetchData();
    },
    onHoverItem: debounce(function (item) {
      this.$root.$emit("hover:complex-search", item);
    }, 200),
    getItemSearch() {
      return this.showItemsSearch;
    },
    async onChangeFilter() {
      await this.onSearch();
      if (this.pagination.total > 0) {
        this.panels = [];
        if (this.isMobile) this.onShowMap();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-input {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  width: 100%;
}
.mobile-mini-search {
  position: fixed;
  top: 65px;
  left: 0;
  width: 100%;
  z-index: 1;
}
.complex-search-container {
  animation-name: expand-height;
  animation-duration: 0.2s;
  height: 100%;
}
@keyframes expand-height {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
</style>
